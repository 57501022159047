import React, { useState, FormEvent } from "react";

import firebase from "firebase/app";
import "firebase/firestore";

import LogoImg from "./img/logo.png";
import ServicesCertifiedImg from "./img/services_certified.jpg";
import ServicesCorporateImg from "./img/services_corporate.jpg";
import ServicesSchoolImg from "./img/services_school.jpg";
import TeamLukeImg from "./img/team-luke.jpg";
import TeamPeterImg from "./img/team-peter.jpg";
import TeamSarahImg from "./img/team-sarah.jpg";
import TeamTenielleImg from "./img/team-tenielle.jpg";
import Bg1Img from "./img/top_1.jpg";

const firestore = firebase.firestore();

const App: React.FC = () => {
  const blankForm = {
    name: "",
    email: "",
    message: "",
    error: "",
    thankyou: "",
    sending: false
  };

  const [form, setForm] = useState(blankForm);

  const setField = (field: string) => ({
    target: { value }
  }: {
    target: { value: string };
  }) => {
    setForm({
      ...form,
      [field]: value
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setForm({
      ...form,
      sending: true,
      error: ""
    });
    firestore
      .collection("contactForm")
      .add({
        from: form.email,
        text: `
        ${form.message}

        From:
        ${form.name}
      `
      })
      .then(() => {
        setForm({
          ...blankForm,
          sending: false,
          error: "",
          thankyou: "Thankyou, your message has been sent successfully."
        });
      })
      .catch(() => {
        setForm({
          ...form,
          sending: false,
          error: "An error occured sending your message, please try again!",
          thankyou: ""
        });
      });
  };

  return (
    <div className="App">
      <section className="home-slider">
        <div className="slider-active">
          <div
            className="single-slider overlay"
            style={{ backgroundImage: `url(${Bg1Img})` }}
            data-stellar-background-ratio="0.5"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
                  <div className="slider-text text-center">
                    <div className="logo">
                      <a href="/">
                        <img src={LogoImg} alt="#" width="250" />
                      </a>
                    </div>
                    <h1>
                      Global <span>Esports</span> Institute
                    </h1>
                    <p>
                      We are the knowledge leaders in esports - research,
                      education and training.
                    </p>
                    <br />
                    <p>
                      Our purpose is to build a diverse esports community,
                      connected globally through the values of equality, welfare
                      and integrity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a id="services" />
      <section className="courses section-bg section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2>
                  Our <span>Services</span>
                </h2>
                <p>
                  We offer a range of services from workshops, seminars, half
                  day and full day workshops for schools and companies.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="course-slider">
                <div className="single-course">
                  <div className="course-head overlay">
                    <img src={ServicesSchoolImg} alt="#" />
                  </div>
                  <div className="single-content">
                    <h4>
                      <a href="course-single.html">
                        <span>SCHOOLS &amp; UNIVERSITIES</span>Welfare Workshops
                      </a>
                    </h4>
                    <p>
                      Give your students, parents and teachers the knowledge and
                      guidance they need to safely engage with Esports Industry
                      through a GESI Workshop held on campus.
                    </p>
                  </div>
                  <div className="course-meta">
                    <a href="#contact" className="price">
                      Enquire
                    </a>
                  </div>
                </div>
                <div className="single-course">
                  <div className="course-head overlay">
                    <img src={ServicesCorporateImg} alt="#" />
                  </div>
                  <div className="single-content">
                    <h4>
                      <a href="course-single.html">
                        <span>CORPORATE</span>Strategy Consultation
                      </a>
                    </h4>
                    <p>
                      Learn how to mitigate the risk of navigating and engaging
                      in the Esports industry by arming yourself with the
                      knowledge and expertise to make sound strategic decisions.
                    </p>
                  </div>
                  <div className="course-meta">
                    <div className="meta-left" />
                    <a href="#contact" className="price">
                      Enquire
                    </a>
                  </div>
                </div>

                <div className="single-course">
                  <div className="course-head overlay">
                    <img src={ServicesCertifiedImg} alt="#" />
                  </div>
                  <div className="single-content">
                    <h4>
                      <a href="course-single.html">
                        <span>CERTIFICATION</span>GESI Certified Acreditation
                      </a>
                    </h4>
                    <p>
                      Upgrade your Esports knowledge and professional industry
                      skills by getting GESI Certified today via one of our
                      workshops, seminars or strategic consultation sessions.
                    </p>
                  </div>
                  <div className="course-meta">
                    <div className="meta-left" />
                    <a href="#contact" className="price">
                      Enquire
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <a id="contact" />
      <section
        className="enquire overlay section"
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="enquire-form">
                    <div className="form-title">
                      <h2>Contact Us</h2>
                      <p>Specialising in all things Esports!</p>
                    </div>
                    <form className="form" onSubmit={onSubmit}>
                      <div className="form-group">
                        <label>Enter Your name</label>
                        <input
                          name="name"
                          type="text"
                          value={form.name}
                          onChange={setField("name")}
                          placeholder="John Mathew"
                        />
                      </div>
                      <div className="form-group">
                        <label>Enter Your Email</label>
                        <input
                          name="email"
                          type="email"
                          value={form.email}
                          onChange={setField("email")}
                          placeholder="john@youremail.com"
                        />
                      </div>
                      <div className="form-group">
                        <label>Type your messages</label>
                        <textarea
                          id="message"
                          value={form.message}
                          onChange={setField("message")}
                          placeholder="i would like to organise a consultation..."
                        />
                      </div>
                      <div className="form-group button">
                        <button
                          type="submit"
                          className="btn"
                          disabled={form.sending}
                        >
                          {form.sending ? (
                            <i className="fa fa-spinner fa-spin" />
                          ) : (
                            "Send Request"
                          )}
                        </button>
                      </div>
                      {form.error && (
                        <div className="form-errors">{form.error}</div>
                      )}
                      {form.thankyou && (
                        <div className="form-thankyou">{form.thankyou}</div>
                      )}
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="enquire-right">
                    <div className="section-title">
                      <h2>Consultation Services</h2>
                      <p>
                        Our team is uniquely qualified and experienced to
                        provide you with customised strategy and information on
                        esports.
                      </p>
                      <p>
                        Whether you’re a player, parent, coach or corporate
                        partner, we can work with you to ensure you engage with
                        esports positively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <a id="team" />
      <section className="team section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2>
                  Meet Our <span>Team</span>
                </h2>
                <p>
                  Our team is uniquely qualified and experienced to provide you
                  with customised strategy and information on esports.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-team active">
                <img src={TeamSarahImg} alt="Marketing Professor" />
                <div className="team-name">
                  <h4 className="name">Sarah Kelly</h4>
                  <h5 className="work">Marketing Professor</h5>
                  <p>
                    Associate Professor in marketing and law and the marketing
                    discipline and currently leading a global sports technology
                    accelerator.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-team active">
                <img src={TeamPeterImg} alt="Esports Expert" />
                <div className="team-name">
                  <h4 className="name">Peter Souvlis</h4>
                  <h5 className="work">Esports Expert</h5>
                  <p>
                    Esports industry expert with over 15 years of experience in
                    building successful Esports teams, brands and products.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-team active">
                <img src={TeamTenielleImg} alt="Esports Representative" />
                <div className="team-name">
                  <h4 className="name">Tenielle Lynch</h4>
                  <h5 className="work">Esports Representative</h5>
                  <p>
                    An experienced teacher in Australia and overseas, Tenielle
                    coordinates and facilitates High School esports workshops
                    and tournaments in Queensland; Connecting students and
                    communities from rural and metro areas through video gaming.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-team active">
                <img src={TeamLukeImg} alt="Software Engineer" />
                <div className="team-name">
                  <h4 className="name">Luke D'Alessandro</h4>
                  <h5 className="work">Software Engineer</h5>
                  <p>
                    Software engineer and gaming enthusiest with more than 10
                    years experience developing mobile and web applications for
                    startups.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <a id="contact" />
      <footer className="footer overlay section">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-widget about">
                  <div className="logo">
                    <a href="#">
                      <img src={LogoImg} alt="#" />
                    </a>
                  </div>
                  <ul className="list">
                    <li>
                      <i className="fa fa-envelope" />
                      Email:{" "}
                      <a href="mailto:info@globalesportsinstitute.com">
                        info@globalesportsinstitute.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="single-widget useful-links">
                  <h2>Useful Links</h2>
                  <ul>
                    <li>
                      <a href="#services">
                        <i className="fa fa-angle-right" />
                        Services
                      </a>
                    </li>
                    <li>
                      <a href="#contact">
                        <i className="fa fa-angle-right" />
                        Consultation
                      </a>
                    </li>
                    <li>
                      <a href="#team">
                        <i className="fa fa-angle-right" />
                        Team
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12" />
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bottom-head">
                  <div className="row">
                    <div className="col-12">
                      <ul className="social" />
                      <div className="copyright">
                        <p>
                          © Copyright 2019{" "}
                          <a href="#">Global Esports Institute</a>. All Rights
                          Reserved
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
