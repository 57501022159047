import firebase from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBCUe5RCuCmq6Y0K_Z4QN9dbULtWgh3IbM",
  authDomain: "gesi-prd.firebaseapp.com",
  databaseURL: "https://gesi-prd.firebaseio.com",
  projectId: "gesi-prd",
  storageBucket: "gesi-prd.appspot.com",
  messagingSenderId: "109049226423",
  appId: "1:109049226423:web:4f81938a50697754"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
